<template>
  <section
      id="features"
      class="grey lighten-3 px-3"
  >

    <v-container
        :mt-5="$vuetify.breakpoint.mdAndUp"
        :pt-5="$vuetify.breakpoint.mdAndUp"
        px-0
        pb-0
    >
      <v-row
          :class="[$vuetify.breakpoint.mdAndUp && 'my-5']"
          align="center"
      >

        <v-col
            cols="12"
            md="6"
        >
          <h3
              class="headline font-weight-regular mb-3 mt-2"
          >
            ZSM IT UG (haftungsbeschränkt)<br>
          </h3>
          <p
              class="body-1 font-weight-light"
          >
            <strong>Geschäftsführer:</strong> Felix Saup, Yuhong Zhao<br>
            <strong>Anschrift:</strong><br>
            ZSM-IT UG <br>
            Bleichstr. 59<br>
            63065 Offenbach am Main<br>
            <strong>Telefon:</strong> 0151 56358818<br>
            <strong>E-Mail:</strong> info@liefer.menu<br>
            <strong>Handelsregisternummer:</strong> HRB 121288 <br>
            <strong>USt.-IdNr.:</strong> DE305003286<br>
          </p>
        </v-col>
        <v-col cols="12">
          <v-btn text :to="{name:'Home'}">Zurück</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
  })
}
</script>
